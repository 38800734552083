/** Zone error **/

.error {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error h1 {
    color: aliceblue;
    text-align: center;
}

#alerte {
    font-size: 6vw;
    text-align: center;
}

/** Media queries **/

@media (max-width: 700px) {
    .error {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    #alerte {
        font-size: 8vw;
    }
}

@media (min-width: 701px) and (max-width: 1000px) {
    .error {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    #alerte {
        font-size: 9vw;
    }
}

/** Media queries landscape **/

@media (max-width: 700px) and (orientation: landscape) {
    .error {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    #alerte {
        font-size: 7vw;
    }
}