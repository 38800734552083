/** Zone machines alimentaires **/

.presentation_machines_mascottes {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../Illustrations/illustrations_machines-alimentaires.avif"), url("../Illustrations_JPG/illustrations_machines-alimentaires.jpg");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 550px;
}

#mascotte_presentation {
    background-image: url("../Illustrations/mascottes_illustration.avif"), url("../Illustrations_JPG/mascottes_illustration.jpg");
    background-position: center;
}

.presentation_machines_mascottes h2 {
    color: aliceblue;
}

.presentation_machines_mascottes div {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2%;
    margin-right: 30%;
    margin-top: 6%;
    margin-bottom: 6%;
    border-radius: 10px;
    padding: 0% 2%;
}

.all_machines_mascots {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin: 1%;
}

.all_machines_mascots img {
    width: 100%;
    height: 550px;
    border-radius: 10px;
    transition: transform 300ms ease-in-out;
    box-shadow: 8px 8px 20px black;
}

.all_machines_mascots img:hover {
    opacity: 0.8;
    transform: translateY(-1.5%);
    box-shadow: 11px 11px 20px black;
}

@media screen and (max-width: 900px) {

    .presentation_machines_mascottes {
        height: 250px;
    }

    .all_machines_mascots img {
        height: 200px;
    }
}


@media (min-width: 768px) and (max-width: 1300px) {

    .presentation_machines_mascottes {
        height: auto;
    }

    .all_machines_mascots img {
        height: 400px;
    }
}

@media (min-width: 430px) and (max-width: 767px) {

    .presentation_machines_mascottes {
        height: 500px;
    }

    .all_machines_mascots img {
        height: 400px;
    }
}