#produits_moment_titre {
    color: rgba(255, 124, 228, 1);
    text-align: center;
}

.carrousel_element {
    background-color: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(255, 124, 228, 1);
    height: 70vh;
}

.carousel .thumbs-wrapper {
    display: none;
}

.carrousel_element img {
    width: 100%;
    height: 100%;
    filter: brightness(90%);
    z-index: 10;
}

.nom_produit {
    z-index: 20;
    position : absolute;
    bottom: 10%;
    transform: translateY(-50);
    left: 13%;
}

.carrousel_element h1 {
    color: rgba(255, 124, 228, 1);
}