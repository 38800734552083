/** Header Home */

#HeaderHome {
    background-image: url("../Illustrations/illustration_marriage.avif"), url("../Illustrations_JPG/illustration_marriage.jpg");
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    background-attachment: fixed;
    filter: brightness(100%);
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 100px 100px auto auto;
    padding-top: 0;
}

#HeaderHome #banniere_droite {
    height: 200px;
    grid-column-start: 3;
    grid-column-end: 4;
    background-image: url("../Illustrations/baniere_farandole_droite.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}

#HeaderHome #banniere_gauche {
    height: 200px;
    grid-column-start: 1;
    grid-column-end: 2;
    background-image: url("../Illustrations/baniere_farandole_gauche.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#HeaderHome #logo {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    padding-top: 6%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#HeaderHome nav {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

.bienvenue {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 4;
}

.bienvenue_text {
    display: flex;
    flex-direction: column;
    gap: 5%;
    margin-top: 5%;
    margin-right: 12%;
    margin-left: 12%;
    padding: 2%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}

.contact_home {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn_contact_home {
    color: aliceblue;
    font-family: 'Kalam', cursive;
    font-size: 1.4vw;
    background-color: rgba(255, 124, 228, 1);
    text-decoration: none;
    text-align: center;
    border: 1px solid aliceblue;
    border-bottom: 4px solid black;
    border-left: 4px solid black;
    border-radius: 10px;
    padding: 0% 1%;
    margin-right: 5%;
    transition: transform 300ms ease-in-out;
}

.btn_contact_home:visited {
    color: aliceblue;
}

.btn_contact_home:hover {
    transform: scale(1.05);
}

.btn_contact_home:active {
    transform: scale(0.95);
    border: 1px solid aliceblue;
}

.bienvenue h1 {
    color: rgba(255, 124, 228, 1);
}

.bienvenue p,
.bienvenue h2 {
    color: aliceblue;
}

.bienvenue p {
    text-align: justify;
}

.carrousel {
    margin-top: 2%;
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 4;
}

.carrousel_element {
    margin-right: 12%;
    margin-left: 12%;
    margin-bottom: 3%;
}

.control-arrow {
    width: 12vw;
}

@media (min-width: 2200px) {

    #HeaderHome {
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: 200px 200px auto auto;
    }

    #HeaderHome #banniere_droite {
        height: 300px;
    }
    
    #HeaderHome #banniere_gauche {
        height: 300px;
    }

    #HeaderHome #logo img {
        height: 150px;
        padding-top: 8%;
    }

    .bienvenue {
        margin-top: 5%;
    }

    .bienvenue h1 {
        font-size: 2vw;
    }

    .bienvenue h2 {
        font-size: 1.5vw;
    }

    .bienvenue p {
        font-size: 1vw;
    }
}

@media (min-width: 1900px) {

    #HeaderHome #logo {
        padding-top: 3%;
    }
    
    .bienvenue div {
        margin-top: 1%;
        margin-right: 12%;
        margin-left: 12%;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    }

    .carrousel_element {
        margin-right: 12%;
        margin-left: 12%;
    }
}

@media (min-width: 767px) and (max-width: 900px) {

    #HeaderHome {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: auto auto auto auto auto;
    }

    #HeaderHome #banniere_droite {
        height: 50px;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: left;
    }

    #HeaderHome #banniere_gauche {
        height: 70px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: right;
    }

    #HeaderHome #logo {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #HeaderHome nav {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .bienvenue {
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .bienvenue div {
        margin-top: 15%;
        margin-right: 12%;
        margin-left: 12%;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    }

    .bienvenue h1 {
        font-size: 5.5vw;
    }

    .bienvenue h2 {
        font-size: 4vw;
    }

    .bienvenue p {
        font-size: 3.5vw;
    }

    .btn_contact_home {
        font-size: 3.5vw;
        border-bottom: 3px solid black;
        border-left: 3px solid black;
    }

    .carrousel {
        grid-row-start: 5;
        grid-row-end: 6;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .carrousel_element {
        margin-right: 12%;
        margin-left: 12%;
    }
}

@media (min-width: 901px) and (max-width: 1300px) {

    #HeaderHome {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: auto auto auto auto auto;
    }

    #HeaderHome #banniere_droite {
        height: 200px;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: left;
    }

    #HeaderHome #banniere_gauche {
        height: 200px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: right;
    }

    #HeaderHome #logo {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #HeaderHome nav {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .bienvenue {
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .bienvenue div {
        margin-top: 15%;
        margin-right: 12%;
        margin-left: 12%;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    }

    .btn_contact_home {
        font-size: 3vw;
        border-bottom: 5px solid black;
        border-left: 5px solid black;
        border-radius: 20px;
    }

    .bienvenue h1 {
        font-size: 5vw;
    }

    .bienvenue h2 {
        font-size: 3.5vw;
    }

    .bienvenue p {
        font-size: 3vw;
    }

    .carrousel {
        grid-row-start: 5;
        grid-row-end: 6;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .carrousel_element {
        margin-right: 12%;
        margin-left: 12%;
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    
    #HeaderHome {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: auto auto auto auto auto;
    }

    #HeaderHome #banniere_droite {
        height: 100%;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: left;
    }

    #HeaderHome #banniere_gauche {
        height: 100%;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: right;
    }

    #HeaderHome #logo {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #HeaderHome nav {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .bienvenue {
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .bienvenue div {
        margin-top: 20%;
        margin-right: 12%;
        margin-left: 12%;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    }

    .btn_contact_home {
        font-size: 3vw;
        border-bottom: 3px solid black;
        border-left: 3px solid black;
    }

    .bienvenue h1 {
        font-size: 5vw;
    }

    .bienvenue h2 {
        font-size: 4vw;
    }

    .bienvenue p {
        font-size: 3.5vw;
    }

    .carrousel {
        grid-row-start: 5;
        grid-row-end: 6;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .carrousel_element {
        margin-right: 12%;
        margin-left: 12%;
    }
}

@media (min-width: 381px) and (max-width: 600px) {
    #HeaderHome {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: auto auto auto auto auto;
    }

    #HeaderHome #banniere_droite {
        height: 100%;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: left;
    }

    #HeaderHome #banniere_gauche {
        height: 100%;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: right;
    }

    #HeaderHome #logo {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #HeaderHome nav {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .bienvenue {
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .bienvenue div {
        margin-top: 15%;
        margin-right: 10%;
        margin-left: 10%;
        margin-bottom: 10%;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    }

    .btn_contact_home {
        font-size: 3vw;
        border-bottom: 3px solid black;
        border-left: 3px solid black;
    }

    .bienvenue h1 {
        font-size: 6vw;
    }

    .bienvenue h2 {
        font-size: 5vw;
    }

    .bienvenue p {
        font-size: 4vw;
    }

    .carrousel {
        grid-row-start: 5;
        grid-row-end: 6;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .carrousel_element {
        height: 50vh !important;
        margin-right: 10%;
        margin-left: 10%;
    }
}

@media (min-width: 200px) and (max-width: 380px) {

    #HeaderHome {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: auto auto auto auto auto;
    }

    #HeaderHome #banniere_droite {
        height: 100%;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: left;
    }

    #HeaderHome #banniere_gauche {
        height: 100%;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        background-position: right;
    }

    #HeaderHome #logo {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #HeaderHome nav {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .bienvenue {
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .bienvenue div {
        margin-top: 20%;
        margin-right: 10%;
        margin-left: 10%;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    }

    .btn_contact_home {
        font-size: 3vw;
        border-bottom: 3px solid black;
        border-left: 3px solid black;
    }

    .bienvenue h1 {
        font-size: 5vw;
    }

    .bienvenue h2 {
        font-size: 4vw;
    }

    .bienvenue p {
        font-size: 3.5vw;
    }

    .carrousel {
        grid-row-start: 5;
        grid-row-end: 6;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .carrousel_element {
        height: 40vh !important;
        margin-right: 10%;
        margin-left: 10%;
    }
}