/** Zone footer */

footer {
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0%;
}

footer p {
  color: rgba(240, 248, 255, 1);
  font-size: 1vw;
  font-family: 'Kalam', cursive;
  text-align: center;
}

.liens_contact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#contact a {
  font-family: 'Kalam', cursive;
  text-decoration: none;
  color: aliceblue;
  transition: transform 300ms ease-in-out;
}

#contact a:hover {
  color: rgba(255, 124, 228, 1);
}

#contact {
  transition: transform 300ms ease-in-out;
}

#contact:hover {
  transform: scale(1.05);
}

#facebook {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#facebook img {
  width: 50px;
  transition: transform 300ms ease-in-out;
}

#facebook img:hover {
  transform: scale(1.1);
}

@media (max-width: 1300px) {
  footer p {
    font-size: 2vw;
  }

  #contact a {
    font-size: 2.5vw;
  }

  #facebook img {
    width: 60px;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  footer p {
    font-size: 2vw;
  }

  #contact a {
    font-size: 2.5vw;
  }

  #facebook img {
    width: 60px;
  }
}

@media (min-width: 430px) and (max-width: 767px) {
  footer p {
    font-size: 2.5vw;
  }

  #contact a {
    font-size: 3vw;
  }

  #facebook img {
    width: 40px;
  }
}

@media (min-width: 380px) and (max-width: 429px) {
  footer p {
    font-size: 2.5vw;
  }

  #contact a {
    font-size: 3vw;
  }

  #facebook img {
    width: 35px;
  }
}

@media (min-width: 200px) and (max-width: 380px) {
  footer p {
    font-size: 2.5vw;
  }

  #contact a {
    font-size: 3vw;
  }

  #facebook img {
    width: 30px;
  }
}