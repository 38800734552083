/** Zone deco **/

.presentation_deco {
    background-image: url("../Illustrations/illustration_deco.avif"), url("../Illustrations_JPG/illustration_deco.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.presentation_deco div {
    color: aliceblue;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2%;
    border-radius: 10px;
    position: relative;
    margin-right: 10%;
    margin-left: 3%;
}

.presentation_deco p {
    font-size: 2vw;
}

/** Media queries **/

@media (max-width: 390px) {
    .presentation_deco {
        height: 600px;
    }

    .presentation_deco p {
        font-size: 3vw;
    }
}

@media (min-width: 390px) and (max-width: 700px) {
    .presentation_deco {
        height: 750px;
    }

    .presentation_deco p {
        font-size: 4vw;
    }
}

@media (min-width: 701px) and (max-width: 1000px) {
    .presentation_deco {
        height: 1100px;
    }
    .presentation_deco p {
        font-size: 3vw;
    }
}

@media (min-width: 1001px) and (max-width: 1400px) {
    .presentation_deco {
        height: 1100px;
    }
}

/** Media queries landscape **/

@media (max-width: 700px) and (orientation: landscape) {
    .presentation_deco {
        height: 600px;
    }

    .presentation_deco p {
        font-size: 3vw;
    }
}