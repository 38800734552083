/** Zone de présentation des produits **/

.products_presentation {
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 550px;
}

.products_description {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
    margin-right: 30%;
    margin-top: 6%;
    margin-bottom: 6%;
    border-radius: 10px;
    padding: 0% 2%;
}

h2 {
    color: aliceblue;
}

.products_presentation p {
    color: aliceblue;
}

#compositions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1%;
}

#compositions h2 {
    color: aliceblue;
    font-size: 3vw;
}

@media screen and (max-width: 900px) {

    .products_description {
        margin-top: 13%;
        margin-bottom: 13%;
    }

    .products_presentation p {
        color: aliceblue;
        font-size: 3vw;
    }

    #compositions {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1%;
    }

    #compositions h2 {
        color: aliceblue;
        font-size: 3vw;
    }

    .products_presentation {
        height: 250px;
    }
}

@media (min-width: 768px) and (max-width: 1300px) {

    .products_description {
        margin-top: 9%;
        margin-bottom: 9%;
        padding: 0% 2%;
    }

    .products_presentation p {
        color: aliceblue;
        font-size: 2.5vw;
    }

    #compositions h2 {
        color: aliceblue;
        font-size: 3vw;
    }

    .products_presentation {
        height: auto;
    }

}


@media (min-width: 430px) and (max-width: 767px) {

    .products_description {
        margin-top: 9%;
        margin-bottom: 9%;
        padding: 0% 2%;
    }

    .products_presentation p {
        color: aliceblue;
        font-size: 2.5vw;
    }

    #compositions h2 {
        color: aliceblue;
        font-size: 3vw;
    }

    .products_presentation {
        height: 500px;
    }
}