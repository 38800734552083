/** Zone globale **/

body {
  margin: 0;
  padding: 0;
  background-color: rgb(236, 140, 217);
  text-shadow: 1px 1px 1px black;
}

img {
  object-fit: cover;
}

h1 {
  font-family: 'Kalam', cursive;
  font-size: 3vw;
  color: rgba(255, 124, 228, 1);
}
html {
  scroll-behavior: smooth;
}

h2 {
  font-family: 'Kalam', cursive;
  font-size: 2vw;
}

p {
  font-size: 1.5vw;
  font-family: 'Kalam', cursive;
}

.btn {
  box-shadow: 2px 2px 10px black;
  border-radius: 10px;
  transition: transform 300ms ease-in-out;
  font-size: 1vw;
  text-align: center;
  font-family: 'Kalam', cursive;
  background-color: aliceblue;
  color: rgba(255, 124, 228, 1);
  margin: 1%;
}

.btn:hover {
  box-shadow: 4px 4px 15px black;
  transform: translateY(-7%);
}

.btn:active {
  margin-top: 1%;
  box-shadow: none;
}

/** Zone médias queries **/

@media screen and (max-width: 900px) {

  h1 {
    font-size: 5vw;
  }

  h2 {
    font-size: 3.5vw;
  }

  p {
    font-size: 2vw;
  }

}

@media (min-width: 768px) and (max-width: 1300px) {

  /** Zone globale **/
  h1 {
    font-size: 5vw;
  }

  h2 {
    font-size: 3.5vw;
  }

  p {
    font-size: 2vw;
  }

}

@media (min-width: 430px) and (max-width: 767px) {

  /** Zone globale **/
  h1 {
    font-size: 5vw;
  }

  h2 {
    font-size: 3.5vw;
  }

  p {
    font-size: 2vw;
  }

}