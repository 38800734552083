/** Zone mentions legales **/

.presentation_mentions {
    background-image: url("../Illustrations/vitrine.avif"), url("../Illustrations_JPG/vitrine.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.presentation_mentions div {
    color: aliceblue;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2%;
    border-radius: 10px;
    position: relative;
    margin: 1%;
}

.presentation_mentions h2 {
    color: rgba(255, 124, 228, 1);
}

.presentation_mentions p {
    font-size: 2vw;
}

.presentation_mentions a {
    color: rgba(255, 124, 228, 1);
    text-decoration: none;
    transition: color 300ms ease-in-out;
}

.presentation_mentions a:hover {
    color: aliceblue;
    text-decoration: underline;
}

.presentation_mentions a:visited {
    color: rgba(255, 124, 228, 1);
}

/** Media queries **/

@media (max-width: 390px) {
    .presentation_mentions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .presentation_mentions p {
        font-size: 3vw;
    }
}

@media (min-width: 390px) and (max-width: 700px) {
    .presentation_mentions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .presentation_mentions p {
        font-size: 4vw;
    }
    
}

@media (min-width: 701px) and (max-width: 1000px) {
    .presentation_mentions {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .presentation_mentions p {
        font-size: 3vw;
    }
}

/** Media queries landscape **/

@media (max-width: 700px) and (orientation: landscape) {

    .presentation_mentions p {
        font-size: 3vw;
    }
}
