/** Zone contact **/

.presentation_contact {
    background-image: url("../Illustrations/vitrine.avif"), url("../Illustrations_JPG/vitrine.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 1250px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.presentation_contact div {
    color: aliceblue;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2%;
    border-radius: 10px;
    position: relative;
    margin-right: 10%;
    margin-left: 3%;
    margin-top: 10%;
}

.presentation_contact p {
    font-size: 2vw;
}

/** Media queries **/

@media (max-width: 390px) {
    .presentation_contact {
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .presentation_contact div {
        margin-top: 0%;
    }

    .presentation_contact p {
        font-size: 3vw;
    }
}

@media (min-width: 390px) and (max-width: 700px) {
    .presentation_contact {
        height: 750px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .presentation_contact div {
        margin-top: 0%;
    }

    .presentation_contact p {
        font-size: 4vw;
    }
    
}

@media (min-width: 701px) and (max-width: 1000px) {
    .presentation_contact {
        height: 1100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .presentation_contact p {
        font-size: 3vw;
    }

    .presentation_contact div {
        margin-top: 0%;
    }
}

@media (min-width: 1001px) and (max-width: 1400px) {
    .presentation_contact {
        height: 1100px;
    }
}

/** Media queries landscape **/

@media (max-width: 700px) and (orientation: landscape) {
    .presentation_contact {
        height: 600px;
    }

    .presentation_contact p {
        font-size: 3vw;
    }
}
